import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const unsubscribeSoloUser = async () => {
	try {
		const mutation = gql`
			mutation {
				RemoveSoloLicence
			}
		`;
		const {
			data: { RemoveSoloLicence }
		} = await apollo.mutate({
			mutation,
			fetchPolicy: 'no-cache'
		});

		return RemoveSoloLicence;
	} catch (e) {
		console.log(e.message);
	}
};

export const getLicenses = async () => {
	const {
		data: { GetLicenses }
	} = await apollo.query({
		query: gql`
			query {
				GetLicenses {
					id
					createdAt
					companyName
					companyId
					userId
					license
					description
					createdBy
					creator {
						id
						name
						firstName
					}
					user {
						id
						name
						firstName
						email
					}
					company {
						id
						name
						vat
						phone
						info
						contactName
						contactFunction
					}
				}
			}
		`,
		fetchPolicy: 'no-cache'
	});

	return GetLicenses;
};
