var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(1, "License") } },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid":
                            _vm.isSubmitted && _vm.$v.script.licenceId.$error,
                        },
                        attrs: {
                          id: "license",
                          clearable: false,
                          options: _vm.licenseOptions,
                          reduce: (option) => option.id,
                        },
                        on: { "option:selected": _vm.handleChangeLicense },
                        model: {
                          value: _vm.script.licenceId,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "licenceId", $$v)
                          },
                          expression: "script.licenceId",
                        },
                      }),
                      _vm.isSubmitted && !_vm.$v.script.licenceId.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(20, "Please, select a license")
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(2, "Company name") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "company-name",
                          type: "text",
                          disabled: "",
                        },
                        model: {
                          value: _vm.script.companyName,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "companyName", $$v)
                          },
                          expression: "script.companyName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(3, "Project (optional)") } },
                    [
                      _c("v-select", {
                        attrs: {
                          id: "project",
                          label: "titre",
                          options: _vm.projectOptions,
                          reduce: (option) => option.id,
                        },
                        on: { input: _vm.deleteSelectedProject },
                        model: {
                          value: _vm.script.projectId,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "projectId", $$v)
                          },
                          expression: "script.projectId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center",
                  attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                },
                [
                  _c("div", { staticClass: "info-script" }, [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(
                          _vm.FormMSG(
                            4,
                            `* If no project is selected, applies to all license's project`
                          )
                        ) +
                        "\n\t\t\t\t"
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: { label: _vm.FormMSG(5, "Script friendly name") },
                    },
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid":
                            _vm.isSubmitted && _vm.$v.script.name.$error,
                        },
                        attrs: {
                          id: "script-friendly-name",
                          type: "text",
                          placeholder: _vm.FormMSG(
                            6,
                            "Enter your script name..."
                          ),
                        },
                        model: {
                          value: _vm.script.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "name", $$v)
                          },
                          expression: "script.name",
                        },
                      }),
                      _vm.isSubmitted && !_vm.$v.script.name.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(21, "Name is required")) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(7, "Script type") } },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid":
                            _vm.isSubmitted && _vm.$v.script.type.$error,
                        },
                        attrs: {
                          id: "script-type",
                          clearable: false,
                          label: "text",
                          options: _vm.typeOptions,
                          reduce: (option) => option.value,
                        },
                        model: {
                          value: _vm.script.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "type", $$v)
                          },
                          expression: "script.type",
                        },
                      }),
                      _vm.isSubmitted && !_vm.$v.script.type.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(22, "Please, select a type")
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(8, "Script file") } },
                    [
                      _c("b-form-file", {
                        ref: "script-file",
                        staticClass: "mb-2",
                        class: {
                          "is-invalid":
                            _vm.isSubmitted && _vm.$v.script.fileName.$error,
                        },
                        attrs: {
                          accept: ".xlsx,.xlsm",
                          placeholder: _vm.FormMSG(
                            9,
                            "Choose a file or drop it here ..."
                          ),
                          "browse-text": _vm.FormMSG(10, "Browse"),
                          "drop-placeholder": _vm.FormMSG(
                            11,
                            "Drop file here ..."
                          ),
                        },
                        on: { change: _vm.handleChangeFileToUploaded },
                        model: {
                          value: _vm.fileToUploaded,
                          callback: function ($$v) {
                            _vm.fileToUploaded = $$v
                          },
                          expression: "fileToUploaded",
                        },
                      }),
                      _vm.isSubmitted && !_vm.$v.script.fileName.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(23, "File is required")) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "pt-28",
                  attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          value: _vm.script.fileName,
                          placeholder: _vm.FormMSG(12, "File name ..."),
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(13, "Language") } },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid":
                            _vm.isSubmitted && _vm.$v.script.language.$error,
                        },
                        attrs: {
                          id: "script-type",
                          clearable: false,
                          label: "text",
                          options: _vm.languageOptions,
                          reduce: (option) => option.value,
                        },
                        model: {
                          value: _vm.script.language,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "language", $$v)
                          },
                          expression: "script.language",
                        },
                      }),
                      _vm.isSubmitted && !_vm.$v.script.language.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(24, "Please, select a language")
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(28, "Output format") } },
                    [
                      _c("v-select", {
                        class: {
                          "is-invalid":
                            _vm.isSubmitted &&
                            _vm.$v.script.outputFileFormat.$error,
                        },
                        attrs: {
                          id: "outputFileFormat",
                          clearable: false,
                          options: _vm.outputFormatOptions,
                          label: "text",
                          reduce: (option) => option.value,
                        },
                        model: {
                          value: _vm.script.outputFileFormat,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "outputFileFormat", $$v)
                          },
                          expression: "script.outputFileFormat",
                        },
                      }),
                      _vm.isSubmitted &&
                      !_vm.$v.script.outputFileFormat.required
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(
                                  _vm.FormMSG(29, "Please, select a format")
                                ) +
                                "\n\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(34, "Custom Filename") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "customExportFileName",
                          type: "text",
                          placeholder: _vm.FormMSG(12, "File name ..."),
                        },
                        model: {
                          value: _vm.script.customExportFileName,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "customExportFileName", $$v)
                          },
                          expression: "script.customExportFileName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(35, "Process Number") } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "processNumber",
                          type: "number",
                          step: "1",
                          min: "0",
                          max: "100000",
                          placeholder: "0",
                        },
                        model: {
                          value: _vm.script.processNumber,
                          callback: function ($$v) {
                            _vm.$set(_vm.script, "processNumber", $$v)
                          },
                          expression: "script.processNumber",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "6", xl: "6" } },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: _vm.FormMSG(36, "Javascript") } },
                    [
                      _c("b-form-file", {
                        ref: "javascript-file",
                        staticClass: "mb-2",
                        attrs: {
                          accept: ".js",
                          placeholder: _vm.FormMSG(
                            9,
                            "Choose a file or drop it here ..."
                          ),
                          "browse-text": _vm.FormMSG(10, "Browse"),
                          "drop-placeholder": _vm.FormMSG(
                            11,
                            "Drop file here ..."
                          ),
                        },
                        on: { change: _vm.handleChangeFileJsToUploaded },
                        model: {
                          value: _vm.fileJsToUploaded,
                          callback: function ($$v) {
                            _vm.fileJsToUploaded = $$v
                          },
                          expression: "fileJsToUploaded",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "pt-28",
                  attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    { attrs: { label: "" } },
                    [
                      _c("b-form-input", {
                        attrs: {
                          value: _vm.script.fileNameJs,
                          placeholder: _vm.FormMSG(12, "File name ..."),
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.script.outputFileFormat === 5
            ? _c(
                "fieldset",
                {
                  staticClass: "my-0",
                  class: `${
                    _vm.$screen.width <= 576
                      ? "card-inside"
                      : "scheduler-border"
                  }`,
                },
                [
                  _c(
                    "legend",
                    {
                      class: `${
                        _vm.$screen.width <= 576
                          ? "card-inside"
                          : "scheduler-border"
                      }`,
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(30, "Custom Script") + " (e.g: ion)"
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "6", md: "6", lg: "6", xl: "6" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(31, "Extension") } },
                            [
                              _c("b-form-input", {
                                class: {
                                  "is-invalid":
                                    _vm.isSubmitted &&
                                    _vm.$v.script.customExtension.$error,
                                },
                                attrs: {
                                  id: "customExtension",
                                  type: "text",
                                  placeholder: _vm.FormMSG(
                                    32,
                                    "Enter your extension..."
                                  ),
                                },
                                model: {
                                  value: _vm.script.customExtension,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.script, "customExtension", $$v)
                                  },
                                  expression: "script.customExtension",
                                },
                              }),
                              _vm.isSubmitted &&
                              _vm.$v.script.customExtension.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              33,
                                              "Extension is required"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-row",
            {
              staticClass: "d-flex justify-content-center align-items-center",
              style: `display: ${
                _vm.displaySubmitBtn ? "block" : "none"
              } !important;`,
            },
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "4", xl: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      ref: "submit",
                      attrs: {
                        type: "submit",
                        size: "md",
                        variant: "primary",
                        block: "",
                      },
                    },
                    [_vm._v(_vm._s(_vm.FormMSG(25, "Save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }